
$primary:#00897b;
// $primary: #038080;
// #00897b teal darken-1

// $secondary: #54595F;
$secondary: #D7EAF0;
$color-text: #7A7A7A;
$accent: #DE6719;
$orange: #DE6719;
$label: white;

$color-orange: #DE6719;
$color-cancel: #7A7A7A;
$color-modal: #E2E2E2;
$color-button: #7A7A7A;

$color-title: #038080;
$color-button: #DE6719;
$color-accept: #038080;
$color-cancel:#7A7A7A;
$color-gray: #e7e4e4;
$color-header: #038080;
$color-dark: #013033;

$color-grey: #AAAAA7;



// #DE6719
// #038080
// #013033
// #AC5717
// #373A36
// #CCCCCC
// #7A7A7A


$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Lato', sans-serif;


