/* You can add global styles to this file, and also import other style files */


@import "src/app/scss/config";

.background-login {
  // height: 400px;
  background-image: url("/assets/mydata/login.png");
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
}

//CSS Views

.background-preview {
    height: 100vh;
    background-image: url("/assets/mydata/viewBackground.png");
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-position: center center;
    display: sticky;
    overflow: auto;
 }
 

 .main-view{
  padding-top: 35px;
 }

 .component-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 }

 @media (max-width: 1200px) {
  .component-div{
    padding-top: 300;
  }
 }

 @media (min-width: 1550px) {
  .component-div{
    margin-top: 75px;
  }
 }

 @media (max-width: 776px) {
  .image-container{
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
 }

 @media (min-width: 776px) {
  .image-container{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}


  .icon-container{
    // padding-top: 5vh;
  }

  .title-image{
    color: $primary;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 30px;
    padding-top: 35px;
    // width: 80vw;
  }

  .title-view{
    color: $primary;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 32px;
    // padding-top: 1vh;
    // width: 80vw;
  }

  .subtitle-project{
    color: #013033;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 29px;
    padding: 20px 0px 20px 0px;
  }


  .card-config{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 70px;
  }


  //Modales CSS

.modalUser{
    max-width: 900px !important;
}

.head-modals{
    background-color: $primary;
    text-align: center;
    height: 55px;
}

.title-modals{
    color: $label;
    justify-content: center;
    justify-content: center;
    text-align: center;
    font-size: 20px !important;
    font-weight: 500;
    // padding-top: 1px;
}

.x-modals{
    color: white;
    font-size: 27px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}

.modals-body{
    background-color: white;
}

.modals-footer{
    background-color: white;
}

.button-cancel-modals{
    background-color: $color-cancel;
    color: $label;
    font-weight: 500;
    padding-top: 7px;
    padding-bottom: 7px;
    border: rgb(255, 255, 255) solid 1px !important;
}

.button-accept-modals{
    background-color: $color-accept;
    color: $label;
    font-weight: 500;
    padding-top: 7px;
    padding-bottom: 7px;
    border: rgb(255, 255, 255) solid 1px !important;
}

.input-label{
    padding-left: 4px !important;
}

.breadcrumbs{
  margin-top: 20px;
  margin-left: 15px !important;
}

.breadcrumbs-disabled{
  color: $color-cancel;
  font-weight: 500;
}

.breadcrumbs-enabled{
  color: $primary !important;
  font-weight: 500 !important;
}

// .button-add{
//   // position: absolute;
//   // margin-left: 69%;
//   // float: right;
//   // background-color: rgb(143, 141, 141);
//   background-color: $primary;
//   color: $label;
//   font-weight: 500;
//   // padding: 4px 3px 2px 3px;
//   padding: 3px 1px 1px 1px;
//   // margin: 0px 0px 0px 0px;
// }


// .div-button-add{
//   display: flex;
//   justify-content: end;
// }



//auth


.title-first{

  // display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: center;
  font-family: $font-primary;
  font-size: 35px;
  font-weight: 700;
  color: black;
  margin-top: 75px;
}

.sub-title-first{

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-primary;
  font-size: 27px;
  font-weight: 700;
  color: white;
  margin-top: 20px;
}

.title-login{
  font-family: $font-primary;
  font-size: 24px;
  font-weight: 700;
  // color: $primary;
  color: $label;
}

.sub-title-login{
  font-family: $font-primary;
  font-size: 20px;
  font-weight: 500;
  color: $label;
}

.icon-login{
  color: white;
  background-color: $primary;
}

.tittle-white{
  font-family: $font-primary;
  font-size: 12px;
  font-weight: 700;
  color: white;
  padding-top: 7px;
  padding-bottom: 7px;
}

.label-div{
  background-color: $primary;
}

.label-login{
  font-family: $font-primary;
  font-size: 15px;
  font-weight: 700;
  // color: $primary;
  color: white;
}

.icon-login{
  color: white;
  background-color: $primary;
}